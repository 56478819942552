<template>
	<!-- begin::page-container(#회원가입) -->
	<div class="page-container" id="page-policies">
		<div class="page-component">
			<header class="page-header">
				<h2 class="title">마케팅 수신 동의를 위한 개인정보 수집·이용 및 제3자 동의서</h2>
			</header>
			<div class="page-body">
				<div class="tos-content">
					<p class="pt-2">마케팅 수신 동의를 위하여 관계 법령에 따라 아래와 같이 개인정보를 활용하는 내용을 알려드리니 자세히 읽으신 후 동의 여부를 결정하여 주십시오. 개인정보 수집·이용 및 제3자 제공 동의한 회원은 가치삽시다의 마케팅 수신을 받으실 수 있으며, 동의 여부와 관계없이 플랫폼 회원가입 가능합니다.</p>
					<h3 class="title pt-3">개인정보 제 3자 제공 내역</h3>
					<div class="tos-table" id="tos-table-03">
						<table>
							<tbody>
								<tr>
									<td style="background-color: #ebebeb">
										<p style="text-align: center"><b>제공받는 기관</b></p>
									</td>
									<td style="background-color: #ebebeb">
										<p style="text-align: center"><b>제공 목적</b></p>
									</td>
									<td style="background-color: #ebebeb">
										<p style="text-align: center"><b>제공하는 항목</b></p>
									</td>
									<td style="background-color: #ebebeb">
										<p style="text-align: center"><b>보유 기간</b></p>
									</td>
								</tr>
								<tr>
									<td><p style="text-align: center; margin: 10px">㈜GS ITM (U.STRA Talk)</p></td>
									<td><p style="text-align: center; margin: 10px">메시지 발송 서비스</p></td>
									<td><p style="text-align: center; margin: 10px">이름, 휴대폰번호, 배송지 주소, 가치삽시다 계정 등</p></td>
									<td><p style="text-align: center; margin: 10px">서비스 제공기간</p></td>
								</tr>
							</tbody>
						</table>
					</div>
					<p class="pt-2">
						<strong>
							※위 개인정보의 제3자 제공에 동의를 거부할 권리가 있습니다.<br />
							그러나 동의를 거부하는 경우, 가치삽시다의 서비스 이용에 제한을 받을 수 있습니다.
						</strong>
					</p>
				</div>
			</div>
		</div>
	</div>
	<!-- end::page-container(#회원가입) -->
</template>
<style>
#page-policies .page-header {
	position: relative;
	padding-top: 50px;
	padding-bottom: 30px;
	border-bottom: 1px solid #ebebeb;
}

#page-policies .page-header .title {
	font-weight: 900;
	font-size: 30px;
	color: #111;
	line-height: 35px;
}

#page-policies .tos-content {
	position: relative;
	padding-top: 10px;
	white-space: pre-line;
}

#page-policies .tos-table {
	overflow-x: auto;
	margin-top: 20px;
	margin-bottom: 30px;
}

#page-policies .tos-table table {
	min-width: 1020px;
	width: 99.9%;
	table-layout: fixed;
	font-size: 15px;
	line-height: 20px;
}

thead,
tbody,
tfoot,
tr,
td,
th {
	border-color: inherit;
	border-style: solid;
	border-width: 0;
}

#page-policies .tos-table tbody td {
	height: 60px;
	padding: 20px;
	border: 1px solid #999;
	vertical-align: top;
}

#page-policies .tos-content p {
	font-size: 15px;
	line-height: 20px;
}

#page-policies .tos-content .title {
	font-size: 20px;
	font-weight: 800;
	line-height: 26px;
}

.pt-2 {
	padding-top: 10px !important;
}

b,
strong {
	font-weight: 800;
}
</style>
<script>
export default {
	name: 'Marketing',
};
</script>
